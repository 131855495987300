import React from 'react'
import { graphql } from 'gatsby'
import {
    mapEdgesToNodes,
    filterOutDocsWithoutSlugs,
    filterOutDocsPublishedInTheFuture
} from '../lib/helpers'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
// import BasicPage from '../components/basic-page'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query ContactsQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      address
    }

    contacts: allSanityContact(
      sort: { fields: [order], order: ASC }
    ) {
        edges {
            node {
                name
                order
                phone
                title
                email
                id
            }
        }
    }
    
  }
`

const ContactPage = props => {
    const { data, errors } = props

    if (errors) {
        return (
            <Layout>
                <GraphQLErrorList errors={errors} />
            </Layout>
        )
    }

    const site = (data || {}).site
    const contactNodes = (data || {}).contacts
        ? mapEdgesToNodes(data.contacts)
        : []

    console.log({ contactNodes });

    if (!site) {
        throw new Error(
            'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
        )
    }

    return (
        <Layout>
            <SEO
                title={site.title}
                description={site.description}
                keywords={site.keywords}
            />
            {/* {site.address} */}
            <section class="section">
                <div class="container">
                    <div class="columns is-variable is-8">
                        <div class="column contacts">
                            <h1 class="title is-3">Contacts</h1>
                            <article id="post-10" class="post-10 page type-page status-publish hentry">
                                {
                                    contactNodes.map((contact, idx) => {
                                        return (
                                            <div key={`contact_${idx}`}>
                                                <h5>{contact.title}</h5>
                                                <p>
                                                    {contact.name && <span>{contact.name} < br /></span>}
                                                    <a href={`mailto:${contact.email}`}>{contact.email}</a><br />
                                                    {contact.phone}
                                                </p>
                                            </div>
                                        )
                                    })
                                }
                            </article>
                        </div>
                        <div className="column is-three-fifths">
                            <h1 className="title is-3">Find us</h1>
                            <article className="map">
                                <br className="clear" />
                                <iframe width="650" height="450" frameborder="0" style={{ border: 0, maxWidth: '100%' }} src="https://www.google.com/maps/embed/v1/place?q=oulton%20village%20hall&key=AIzaSyDiLNsMJ4EprpBwvdkmRh3jCGTS7V-RjJQ" allowfullscreen></iframe>
                            </article>
                            <h1 className="title is-3">Address</h1>
                            <article>
                                <p>
                                    Oulton Village Hall,<br />
                                    Kibblestone Road,<br />
                                    Oulton,
                                    Stone,
                                    Staffordshire<br />
                                    ST15 8UJ.
                                </p>
                            </article>
                        </div>
                    </div>
                </div>
            </section>

        </Layout>
    )
}

export default ContactPage
